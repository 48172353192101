<template>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 793.7 1122.5" :width="width" :height="height" :style="{top: topPx, left: leftPx}">
 <g fill-rule="evenodd">
  <g stroke-width="1.259">
   <path d="m505.98 670.34c-31.576 18.483-123.92-105.21-160.26-109.22-36.344-4.0111-151.14 96.824-178.37 71.85-27.23-24.973 59.228-151.79 51.513-188.19-7.7152-36.396-138.14-117-123.4-150.92 14.747-33.917 160.52 11.398 192.1-7.0854 31.576-18.483 65.76-169.14 102.1-165.12 36.344 4.0112 39.979 158.84 67.209 183.81 27.23 24.973 178.78 12.473 186.5 48.869 7.7152 36.396-135.81 86.77-150.56 120.69-14.747 33.918 44.735 176.84 13.159 195.33z" fill="#646464" fill-opacity=".215" stroke="#54514f" stroke-opacity=".2"/>
   <path d="m500.23 651.36c-30.251 17.33-118.72-98.648-153.54-102.41-34.819-3.7608-144.8 90.783-170.88 67.368-26.088-23.415 56.743-142.32 49.351-176.45-7.3914-34.126-132.35-109.7-118.22-141.51 14.128-31.801 153.78 10.687 184.04-6.6434 30.251-17.33 63.001-158.58 97.82-154.82 34.819 3.7609 38.301 148.93 64.389 172.34 26.088 23.415 171.28 11.694 178.68 45.82 7.3915 34.126-130.11 81.356-144.24 113.16-14.128 31.802 42.858 165.81 12.607 183.14z" :fill="fill1" :stroke="stroke1"/>
  </g>
  <path d="m385.03 146.43c-31.188-3.3687-66.359 142.52-93.456 158.04-27.096 15.523-161.84-30.266-174.49-1.7809-12.655 28.485 108.91 103.94 115.53 134.51 6.6206 30.567-69.473 146.76-46.106 167.73 2.7259 2.4466-22.505 1.4324 5.2634 1.311-20.121-50.09 53.947-134.94 47.843-163.12-6.6206-30.567-124.36-107.96-111.71-136.44 12.655-28.485 139.71 19.292 166.8 3.7694 26.497-15.179 55.104-143.95 85.521-146.16-3.6686-5.7426 9.6453-17.33 4.8052-17.853zm71.119 175.77c1.0286 1.473 2.0774 2.8835 3.1931 3.8849 18.752 16.832 106.08 13.463 143.32 27.248-14.984-22.592-109.31-18.601-146.51-31.132zm-90.389 225.84c27.957 21.038 65.611 61.596 91.907 75.41-30.136-22.194-68.121-63.463-91.907-75.41z" fill="#fff" fill-opacity=".694" stroke-width=".539"/>
 </g>
</svg>
</template>

<script>
import ThemeMixin from '../theme_mixin'

export default {
  name: 'star',

  mixins: [ThemeMixin]
}
</script>
